import React from 'react';
import styled from 'styled-components';
import {useI18next, Helmet, Trans} from 'gatsby-plugin-react-i18next';
import {styles, media, colors, links} from 'global.config';
import Stars from 'components/Stars';
import Button from 'components/Button';
import Container from 'components/Container';
import SectionHeader from 'components/SectionHeader';
import CurvedBorder from 'components/CurvedBorder';

import EmailMarketingSoftwareImage from 'images/email-marketing-software.svg';
import TestRide from 'images/test-ride-image.svg';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionInverse = styled(IndexSection)`
  color: #4a4a4a;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 80px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 5%;
    right: 5%;
  }
  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionContainerInverse = styled(IndexSectionContainer)`
  min-height: auto;
  padding: 0 4% 60px 4%;
  ul {
    list-style-position: inside;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 235px;
    margin-bottom: 20px;
  }
`;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 400px;
  margin: 30px auto;
  font-weight: 300;
`;

const IllustratedContent = styled.div`
  max-width: 480px;
  margin-left: 6.3%;
  ul {
    color: ${colors.textLight};
    list-style: none;
    margin: 0 0 15px;
    padding: 0;
    line-height: 20px;
  }
  p {
    color: ${colors.textLight};
  }
`;
const IllustratedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px ${styles.containerPadding} 85px;
  img {
    max-width: 100%;
    @media ${media.tablet} {
      max-width: 40%;
    }
  }
  @media ${media.mobile} {
    display: block;
  }
`;

const MainPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <Helmet title={t('Email Marketing Automation')}>
        <meta
          name="description"
          content={t(
            'Use MoonMail, the best Email Marketing Automation platform. Try it now for free!'
          )}
        />
      </Helmet>
      <div className="index-page">
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={EmailMarketingSoftwareImage}
                alt={t('Email marketing software')}
                title={t('OmniChannel Communication Platform')}
                width={296}
                height={201}
              />
              <h1>{t('The best email marketing software for nonprofits and charities')}</h1>
              <IndexSectionSummary>
                {t("MoonMail is your choice if you're looking for simple email marketing")}
              </IndexSectionSummary>
              <p>
                <Button
                  href={links.signUp}
                  title={t('OmniChannel Communication Platform to fly to the moon')}>
                  {t('Try it For Free')}
                </Button>
              </p>
            </IndexSectionContainer>
          </IndexSection>
          <CurvedBorder />
        </Stars>
        <IndexSectionInverse>
          <IndexSectionContainerInverse>
            <Trans parent="h2">Powerful Email Marketing for Nonprofit Organizations</Trans>
            <div>
              <Trans parent="p" i18nKey="Powerful Email Marketing Tools">
                All of the different tools used in email marketing for nonprofits cost time and
                money. Time that could be spent working for your charity recipients and money that
                could be spent supporting the organization to allow you to help even more As your
                business grows however, these tools also grow more expensive.
              </Trans>
              <Trans parent="p" i18nKey="Powerful Email Marketing Offer">
                Your resources are a critical part of helping the recipients. MoonMail offers a way
                to help you keep in touch with donors and volunteers so they remain aware of
                campaigns and any additional help they can provide We want to help facilitate any
                way we can, so we offer a 50% discount for nonprofits and charities on all monthly
                subscriptions.
              </Trans>
              <Button
                href={links.signUp}
                title={t('OmniChannel Communication Platform to fly to the moon')}>
                {t('Try MoonMail')}
              </Button>
            </div>
          </IndexSectionContainerInverse>
          <IndexSectionContainerInverse>
            <Trans parent="h2">Advantages of Email Marketing for Nonprofits and Charities</Trans>
            <div>
              <Trans parent="p" i18nKey="Advantages of Email Marketing Primary">
                One of the primary way we share information is by forwarding emails Email marketing
                for fundraising helps to build trust keep engagement up with your donors and
                volunteers, and build awareness of your events or urgent campaigns Sending regular
                communications allow them to see and share the impact your organization is having
                and explain how they can help further.
              </Trans>
              <Trans parent="p" i18nKey="Advantages of Email Marketing In Contact">
                We're human we can all get distracted with our busy lives, so remaining in contact
                with donors is a vital piece of your marketing. They remember you much more easily
                if they see your emails regularly and be encouraged to come to you when they're
                ready to help.
              </Trans>
            </div>
          </IndexSectionContainerInverse>
          <IndexSectionContainerInverse>
            <Trans parent="h2">How Can Moon Mail Help Your Email Campaigns?</Trans>
            <div>
              <Trans parent="p" i18nKey="How Can Moon Mail Help Reach All">
                To effectively reach all of your donors, volunteers, and in some cases potential
                partners, you need an email marketing platform built for nonprofits. Your needs are
                unique and it takes an innate understanding of them to help you reach and build
                relationships with your audience.
              </Trans>
              <Trans parent="p" i18nKey="How Can Moon Mail Help Integrate">
                Set up your account with MoonMail and integrate with some of the more than 750 tools
                in our toolbox like the top fundraising apps. Let us streamline the process for you!
                You can customize campaigns to each of your audiences using the personalization and
                segmentation features MoonMail's system helps you automate and track all of your
                email metrics to improve your overall impact.
              </Trans>
              <ul>
                <Trans parent="li">Audience segmentation</Trans>
                <Trans parent="li">Unlimited number of contacts</Trans>
                <Trans parent="li">
                  Connect MoonMail to 750+ business tools Including top fundraising apps
                </Trans>
                <Trans parent="li">Easily track your results</Trans>
                <Trans parent="li">Highly efficient automation</Trans>
              </ul>
            </div>
          </IndexSectionContainerInverse>
        </IndexSectionInverse>
        <section className="test-ride illustrated-section">
          <IllustratedContainer>
            <img
              src={TestRide}
              alt={t('Do a test ride on our OmniChannel Communication Platform')}
            />
            <IllustratedContent>
              <SectionHeader>{t('Fancy a Test Ride Around the Moon  ?')}</SectionHeader>
              <p>{t('Try it yourself. Check our fully functional Demo today.')}</p>
              <Button href={links.demo}>{t('Launch the Demo')}</Button>
            </IllustratedContent>
          </IllustratedContainer>
        </section>
      </div>
    </>
  );
};

export default MainPage;
