import React from 'react';
// @ts-ignore
import {ReactComponent as CurvedBorder} from 'images/curved-border.svg';
// @ts-ignore
import {ReactComponent as CurvedBorderReversed} from 'images/curved-border-reversed.svg';

export default props => {
  const {reversed, ...rest} = props;
  const css = `
    fill:#fff;
    margin-bottom: -10px;
  `;
  return reversed ? (
    <CurvedBorderReversed css={css} {...rest} />
  ) : (
    <CurvedBorder css={css} {...rest} />
  );
};
